import React from "react";
import ForgotPassword from "../components/ForgotPassword";
import StudentNavbar from "../components/Navbars/StudentNavbar";

const InternshipsPage = () => {
  return (
    <>
      <ForgotPassword />
    </>
  );
};
export default InternshipsPage;