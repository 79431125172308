import React from "react";
import StudentProfile from "../components/student/StudentProfile";
import StudentNavbar from "../components/Navbars/StudentNavbar";


const StudentProfilePage = () => {
    return (
        <>  
            <StudentNavbar />
            <StudentProfile />
        </>
    );
};
export default StudentProfilePage;
