import React from 'react';
import StudentNavbar from '../components/Navbars/StudentNavbar';
import JobHome from '../components/student/JobHome';

const StudentHomePage = () => {
  return (
    <>
      <StudentNavbar />
      <JobHome />
    </>
  );
};
export default StudentHomePage;
