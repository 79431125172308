import React from "react";
import Services from "../components/Services";
import IndexNavbar from "../components/Navbars/IndexNavbar";
const ServicesPage = () => {
  return (
    <>
      <IndexNavbar />
      <Services />
    </>
  );
};
export default ServicesPage;
