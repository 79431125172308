import React from "react";
import MentorProfile from "../components/Mentoring/MentorProfile";
import StudentNavbar from "../components/Navbars/StudentNavbar";


const MentorProfilePage = () => {
    return (
        <>  
            <StudentNavbar />
            <MentorProfile />
        </>
    );
};
export default MentorProfilePage;
