import React from "react";

function Services() {
  return(
    <div>
      <p>Our services are currently under development. Please check back soon!</p>
    </div>
  )
}

export default Services;
